import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { getDictionaries } from '1_shared/api/dictionary';
import useSWRWithCache from '1_shared/api/lib/useSWRWithCache';
import { ISpecialistListFilters } from '1_shared/config/interfaces';
import { IOption } from '1_shared/config/interfaces/IOption';
import {
  dayTypeFilterOptions,
  languageOpt,
  sexFilterOptions,
} from '1_shared/constants/filterEnums';
import { Button, Select, TimeRangePicker } from '1_shared/ui';

import changeParameterInRoute from '../../../1_shared/helpers/changeParameterInRoute';
import separateParams from '../../../1_shared/helpers/separateParams';

import { IFilterFormProps } from './interfaces/IFilterFormProps';

import styles from './FilterForm.module.scss';

const FiltersForm = ({ handleFieldChange, onSubmit }: IFilterFormProps) => {
  const { control, watch, reset, getValues } =
    useFormContext<ISpecialistListFilters>();
  const navigate = useNavigate();
  const location = useLocation();

  watch('moneyFilter.minMoneyRate');

  const { data: keyThemesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/key-themes',
    getDictionaries,
  );

  const { data: specialitiesOptions } = useSWRWithCache<IOption<string>[]>(
    '/spec/specialities',
    getDictionaries,
  );

  const addParameterFilter = (fieldName: string, value?: string) => {
    changeParameterInRoute([fieldName, value], location, navigate);
  };

  useEffect(() => {
    reset({ ...getValues(), ...separateParams(location.search) });
    handleFieldChange();
  }, []);

  return (
    <div className={styles.root}>
      <Controller
        name="keyThemes"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            placeholder="Ключевые темы"
            name="keyThemes"
            label="ключевые темы"
            className={styles.workWith}
            options={keyThemesOptions?.reverse()}
            suffixIcon={
              value && (
                <CloseCircleFilled
                  className={styles.icon}
                  onClick={() => {
                    onChange(null);
                    handleFieldChange();
                  }}
                />
              )
            }
            value={value}
            mode="multiple"
            onChange={value => {
              onChange(value || null);
              handleFieldChange();
            }}
          />
        )}
      />

      <Controller
        name="specialties"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder="Любые"
            name="specialties"
            label="специальность"
            value={value}
            className={styles.workWith}
            options={specialitiesOptions}
            suffixIcon={
              value && (
                <CloseCircleFilled
                  className={styles.icon}
                  onClick={() => {
                    onChange(null);
                    handleFieldChange();
                    addParameterFilter('specialties');
                  }}
                />
              )
            }
            onChange={value => {
              onChange(value || null);
              handleFieldChange();
              addParameterFilter('specialties', value);
            }}
          />
        )}
      />
      <Controller
        name="sex"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            placeholder="Не важен"
            name="sex"
            label="пол"
            value={value}
            options={sexFilterOptions}
            suffixIcon={
              value && (
                <CloseCircleFilled
                  className={styles.icon}
                  onClick={() => {
                    onChange(null);
                    handleFieldChange();
                  }}
                />
              )
            }
            onChange={value => {
              onChange(value || null);
              handleFieldChange();
            }}
          />
        )}
      />
      <Controller
        name="language"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder="Не важен"
            name="language"
            label="Язык"
            value={value}
            options={languageOpt}
            suffixIcon={
              value && (
                <CloseCircleFilled
                  className={styles.icon}
                  onClick={() => {
                    onChange(null);
                    handleFieldChange();
                  }}
                />
              )
            }
            onChange={value => {
              onChange(value || null);
              handleFieldChange();
            }}
          />
        )}
      />

      <Controller
        name="slotsFilter"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TimeRangePicker
            onChange={tempValue => {
              onChange(
                value
                  ? {
                      ...value,
                      localTimeFrom: tempValue?.[0],
                      localTimeTo: tempValue?.[1],
                    }
                  : null,
              );
              handleFieldChange();
            }}
            needConfirm={false}
            value={
              value?.localTimeFrom && value?.localTimeTo
                ? [
                    dayjs(value.localTimeFrom, 'HH:mm'),
                    dayjs(value.localTimeTo, 'HH:mm'),
                  ]
                : null
            }
            name="time"
            label="время"
          />
        )}
      />

      <Controller
        name="slotsFilter.dayType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder="Не важен"
            name="dayType"
            label="тип дня"
            value={value}
            options={dayTypeFilterOptions}
            onChange={value => {
              onChange(value || null);
              handleFieldChange();
            }}
            suffixIcon={
              value && (
                <CloseCircleFilled
                  className={styles.icon}
                  onClick={() => {
                    onChange(null);
                    handleFieldChange();
                  }}
                />
              )
            }
          />
        )}
      />
      {!!onSubmit && (
        <Button type="primary" className={styles.btnFilters} onClick={onSubmit}>
          Применить фильтры
        </Button>
      )}
    </div>
  );
};

export default FiltersForm;
